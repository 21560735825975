<template>
  <Layout>
    <template v-slot:sidebar>
      <SidebarMain />
    </template>
    <template v-slot:content>
      <Feature visible="VUE_APP_NSA_1620">
        <PlanComponent />
      </Feature>
      <Feature visible="!VUE_APP_NSA_1620">
        <PlanComponentOld />
      </Feature>
    </template>
  </Layout>
</template>

<script>
import Layout from '@/components/UI/Layout';
import SidebarMain from '@/viewsNew/SidebarMain';
import PlanComponent from '../components/Plan';
import PlanComponentOld from '../components/PlanOld';
import Feature from '@/components/app/Feature';

export default {
  components: {
    Layout,
    SidebarMain,
    PlanComponent,
    PlanComponentOld,
    Feature
  }
};
</script>
